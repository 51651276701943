.Button {
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
    font: inherit;
    padding: 10px;
    margin: 10px;
    font-weight: bold;
}

.Button:disabled{
    color: #ccc;
    cursor: not-allowed;
}

.Success {
    color: #5C9210;
}

.Danger {
    color: #944317;
}

.Alternative {
    color: #faf1f1;
    background-color: #3745c2e0;
    padding: 10px;
    border-radius: 10px;
}
